<template>
  <div class="product_1">
      <div class="product_1_1">

      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.product_1{
    display: flex;
    justify-content: center;
}
.product_1 .product_1_1{
    width: 1200px;
    background-color: #333;
}

</style>